export enum PaymentType {
  Stripe = "stripe",
  Alipay = "alipay",
  Globalpay = "globalpay",
  Saferpay = "saferpay",
  Klarna = "klarna",
  Paygate = "paygate",
  Mollie = "mollie",
  Nexi = "nexi",
  Paypal = "paypal",
}

export interface StripePaymentOptions {
  apiKey?: string;
  clientKey?: string;
  webhookSecret?: string;
}

export interface AlipayPaymentOptions {
  appId?: string;
  gatewayUrl?: string;
  privateKey?: string;
  publicKey?: string;
  alipayPublicKey?: string;
}

export interface GlobalpayPaymentOptions {
  gatewayUrl?: string;
  privateKey?: string;
  publicKey?: string;
  privateKeyPassword?: string;
  merchantNumber?: string;
}

export interface SaferpayPaymentOptions {
  terminalId?: string;
  customerId?: string;
  gatewayUrl?: string;
  username: string;
  password?: string;
}

export interface KlarnaPaymentOptions {
  username?: string;
  password?: string;
  gatewayUrl?: string;
}

export interface PaygatePaymentOptions {
  paygateId?: string;
  password?: string;
}

export interface MolliePaymentOptions {
  apiKey?: string;
}

export interface NexiPaymentOptions {
  secretKey?: string;
}

export interface PaypalPaymentOptions {
  apiUrl?: string;
  clientId?: string;
  clientSecret?: string;
  webhookId?: string;
}

export interface StripePayment {
  id: string;
  name: string;
  type: PaymentType.Stripe;
  options: StripePaymentOptions;
  createdAt: Date;
}

export interface AlipayPayment {
  id: string;
  name: string;
  type: PaymentType.Alipay;
  options: AlipayPaymentOptions;
  createdAt: Date;
}

export interface GlobalpayPayment {
  id: string;
  name: string;
  type: PaymentType.Globalpay;
  options: GlobalpayPaymentOptions;
  createdAt: Date;
}

export interface SaferpayPayment {
  id: string;
  name: string;
  type: PaymentType.Saferpay;
  options: SaferpayPaymentOptions;
  createdAt: Date;
}

export interface KlarnaPayment {
  id: string;
  name: string;
  type: PaymentType.Klarna;
  options: KlarnaPaymentOptions;
  createdAt: Date;
}

export interface PaygatePayment {
  id: string;
  name: string;
  type: PaymentType.Paygate;
  options: PaygatePaymentOptions;
  createdAt: Date;
}

export interface MolliePayment {
  id: string;
  name: string;
  type: PaymentType.Mollie;
  options: MolliePaymentOptions;
  createdAt: Date;
}

export interface NexiPayment {
  id: string;
  name: string;
  type: PaymentType.Nexi;
  options: NexiPaymentOptions;
  createdAt: Date;
}

export interface PaypalPayment {
  id: string;
  name: string;
  type: PaymentType.Paypal;
  options: PaypalPaymentOptions;
  createdAt: Date;
}

export type Payment =
  | StripePayment
  | AlipayPayment
  | GlobalpayPayment
  | SaferpayPayment
  | KlarnaPayment
  | PaygatePayment
  | MolliePayment
  | NexiPayment
  | PaypalPayment;
