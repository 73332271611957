import { Alert, AlertTitle, Paper, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import ReactMarkdown from "react-markdown";
import rehypeAttrs from "rehype-attr";
import rehypeRaw from "rehype-raw";
import TextInput from "../../../components/form/TextInput";
import LinkRenderer from "../../../components/LinkRenderer";
import { PaypalPayment } from "../../../state/payments";

const PaypalFields = ({ values }: { values: Omit<PaypalPayment, "id"> }) => {
  const { t } = useTranslation();
  return (
    <>
      <Paper sx={{ p: 2, mt: 2 }}>
        <Typography variant="h2">
          {t("setup.payment.paypal.generalTitle")}
        </Typography>
        <TextInput
          key="options.apiUrl"
          name="options.apiUrl"
          type="text"
          title={t("setup.payment.paypal.apiUrl") + ":"}
        />
        <TextInput
          key="options.clientId"
          name="options.clientId"
          type="text"
          title={t("setup.payment.paypal.clientId") + ":"}
        />
        <TextInput
          key="options.clientSecret"
          name="options.clientSecret"
          type="password"
          title={t("setup.payment.paypal.clientSecret") + ":"}
        />
      </Paper>
      <Alert
        severity="info"
        sx={{
          mt: 4,
          "& a": {
            color: (theme) => theme.palette.primary.main,
          },
        }}
      >
        <AlertTitle>{t("setup.payment.paypal.webhookInfoTitle")}</AlertTitle>
        <ReactMarkdown
          components={{ a: LinkRenderer }}
          rehypePlugins={[rehypeRaw, [rehypeAttrs, { properties: "attr" }]]}
        >
          {t("setup.payment.paypal.webhookInfo", {
            joinArrays: "  \n",
            paypalSetupWebhookUrl:
              process.env.REACT_APP_PAYPAL_SETUP_WEBHOOK_URL,
            paypalApiWebhookUrl: `${process.env.REACT_APP_API_BASE_URL}/api/paypal-webhook`,
          })}
        </ReactMarkdown>
      </Alert>
      <Paper sx={{ p: 2, mt: 2 }}>
        <Typography variant="h2">
          {t("setup.payment.paypal.webhooksTitle")}
        </Typography>
        <TextInput
          key="options.webhookId"
          name="options.webhookId"
          type="text"
          title={t("setup.payment.paypal.webhookId") + ":"}
          value={values.options.webhookId}
        />
      </Paper>
    </>
  );
};

export default PaypalFields;
